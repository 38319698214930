var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-5"},[_c('router-link',{staticClass:"dashboard-nav-link px-4",attrs:{"to":{ name: 'ClienteDashboard' }}},[_c('div',{staticClass:"d-flex align-center"},[_c('img',{directives:[{name:"svg-inline",rawName:"v-svg-inline"}],staticClass:"icon mr-2",attrs:{"src":require("@/assets/images/icons/board.svg"),"alt":""}}),_c('span',[_vm._v("Dashboard")])])]),_c('router-link',{staticClass:"dashboard-nav-link px-4",attrs:{"to":{
      name: 'ClientePedidos',
    }}},[_c('div',{staticClass:"d-flex align-center"},[_c('img',{directives:[{name:"svg-inline",rawName:"v-svg-inline"}],staticClass:"icon mr-2",attrs:{"src":require("@/assets/images/icons/shopping-cart.svg"),"alt":""}}),_c('span',[_vm._v("Meu Carrinho ")])]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.produtosCarrinhoTotal)+" ")])]),_c('router-link',{staticClass:"dashboard-nav-link px-4",attrs:{"to":{
      name: 'ClientePedidos',
    }}},[_c('div',{staticClass:"d-flex align-center"},[_c('img',{directives:[{name:"svg-inline",rawName:"v-svg-inline"}],staticClass:"icon mr-2",attrs:{"src":require("@/assets/images/icons/shopping-cart.svg"),"alt":""}}),_c('span',[_vm._v("Meus Pedidos")])]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.pagamentosClienteTotal)+" ")])]),_c('router-link',{staticClass:"dashboard-nav-link px-4",attrs:{"to":{
      name: 'ClienteProdutosFavoritos',
    }}},[_c('div',{staticClass:"d-flex align-center"},[_c('img',{directives:[{name:"svg-inline",rawName:"v-svg-inline"}],staticClass:"icon mr-2",attrs:{"src":require("@/assets/images/icons/shopping-cart.svg"),"alt":""}}),_c('span',[_vm._v("Meus Favoritos")])]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.produtosFavoritosTotal)+" ")])]),_c('router-link',{staticClass:"dashboard-nav-link px-4",attrs:{"to":{ name: 'ClientePerfil' }}},[_c('div',{staticClass:"d-flex align-center"},[_c('img',{directives:[{name:"svg-inline",rawName:"v-svg-inline"}],staticClass:"icon mr-2",attrs:{"src":require("@/assets/images/icons/gear-2.svg"),"alt":""}}),_c('span',[_vm._v("Meu Perfil")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }