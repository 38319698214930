<template>
  <div class="pt-5">
    <router-link :to="{ name: 'ClienteDashboard' }" class="dashboard-nav-link px-4">
      <div class="d-flex align-center">
        <img v-svg-inline class="icon mr-2" src="@/assets/images/icons/board.svg" alt="">
        <span>Dashboard</span>
      </div>
      <!-- <p class="mb-0">5</p>   -->
    </router-link>

    <!-- <router-link to="/product-list" class="dashboard-nav-link px-4">
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/box.svg"
          alt=""
        />
        <span>Produtos</span>
      </div>
      <p class="mb-0">300</p>
    </router-link> -->
    <!-- <router-link to="/edit-product" class="dashboard-nav-link px-4">
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/upload.svg"
          alt=""
        />
        <span>Add New Product</span>
      </div>
      <p class="mb-0">1</p>
    </router-link> -->

    <router-link :to="{
        name: 'ClientePedidos',
      }" class="dashboard-nav-link px-4">
      <div class="d-flex align-center">
        <img v-svg-inline class="icon mr-2" src="@/assets/images/icons/shopping-cart.svg" alt="">
        <span>Meu Carrinho </span>
      </div>
      <p class="mb-0">
        {{ produtosCarrinhoTotal }}
      </p>
    </router-link>

    <router-link :to="{
        name: 'ClientePedidos',
      }" class="dashboard-nav-link px-4">
      <div class="d-flex align-center">
        <img v-svg-inline class="icon mr-2" src="@/assets/images/icons/shopping-cart.svg" alt="">
        <span>Meus Pedidos</span>
      </div>
      <p class="mb-0">
        {{ pagamentosClienteTotal }}
      </p>
    </router-link>

    <router-link :to="{
        name: 'ClienteProdutosFavoritos',
      }" class="dashboard-nav-link px-4">
      <div class="d-flex align-center">
        <img v-svg-inline class="icon mr-2" src="@/assets/images/icons/shopping-cart.svg" alt="">
        <span>Meus Favoritos</span>
      </div>
      <p class="mb-0">
        {{ produtosFavoritosTotal }}
      </p>
    </router-link>

    <!-- <router-link
      :to="{
        name: 'ClienteEnderecoEntrega',
      }"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/shopping-cart.svg"
          alt=""
        >
        <span> Endereço de Entrega</span>
      </div>
      <p class="mb-0">
        1
      </p>
    </router-link> -->

    <router-link :to="{ name: 'ClientePerfil' }" class="dashboard-nav-link px-4">
      <div class="d-flex align-center">
        <img v-svg-inline class="icon mr-2" src="@/assets/images/icons/gear-2.svg" alt="">
        <span>Meu Perfil</span>
      </div>
    </router-link>
    <!--
    <router-link to="/portal/terminar/sessao" class="dashboard-nav-link px-4">
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/gear-2.svg"
          alt=""
        />
        <span>Terminar sessão</span>
      </div>
    </router-link> -->
  </div>
</template>

<script>
  import axios from 'axios'
  import { LOGIN_URL, BASE_URL } from '@/configs/api'

  export default {
    components: {},
    data () {
      return {
        pagamentosClienteTotal: 0,
        produtosFavoritosTotal: 0,
        produtosCarrinhoTotal: 0,
      }
    },
    created () {
      this.getAllProdutosFavoritos()
      this.getClientePagamentos()
      this.loadClienteCarrinhoProdutos()
    },
    methods: {
      loadClienteCarrinhoProdutos () {
        var TOKEN_SESSAO = sessionStorage.getItem('TOKEN')
        const url = `${BASE_URL}/carrinho/get/my/produtos`
        axios
          .get(url, {
            headers: {
              Authorization: 'Bearer ' + TOKEN_SESSAO,
            },
          })
          .then((response) => {
            this.produtosCarrinhoTotal = response.data.data.produtos.length
            // alert(JSON.stringify(response.data.data.produtos.length));
          })
      },
      getClientePagamentos () {
        // this.overlay = true;
        var token = sessionStorage.getItem('TOKEN')
        const url = `${BASE_URL}/listarTodosPagamentosVendaOnlineUserAutenticado`
        axios
          .get(url, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          })
          .then((response) => {
            this.pagamentosClienteTotal = response.data.total
          })
      },
      getAllProdutosFavoritos () {
        // this.loading = true;
        var TOKEN_SESSAO = sessionStorage.getItem('TOKEN')
        const url = `${LOGIN_URL}/empresa/listarProdutosFavoritos`
        axios
          .get(url, {
            headers: {
              Authorization: 'Bearer ' + TOKEN_SESSAO,
            },
          })
          .then((response) => {
            this.produtosFavoritosTotal = response.data.produtos.length
          })
      },
    },
  }
</script>

<style lang="scss">
.dashboard-nav-link {
  color: rgb(125, 135, 156) !important;
  text-decoration: none;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-left: 4px solid #fff;
  margin-bottom: 20px;
  svg {
    width: 20px;
  }
  &.router-link-exact-active {
    color: #d23f57 !important;
    border-left: 4px solid #d23f57;
  }
  &:hover {
    color: #d23f57 !important;
    border-left: 4px solid #d23f57;
  }
}
</style>
