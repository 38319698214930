<template>
  <v-container class="py-6">
    <v-row>
      <v-col cols="12">
        <div class="box-wrapper">
          <div class="box-overlay" :class="{ open: isSidebar }" @click="isSidebar = !isSidebar"></div>
          <div class="box-sidebar pb-3 shadow-sm" :class="{ open: isSidebar }">
            <DashboardSidebarTwo />
          </div>
          <div class="box-content">
            <div class="d-flex justify-end pa-2 d-block d-md-none">
              <v-btn icon @click="isSidebar = !isSidebar">
                <v-icon dark> mdi-format-list-bulleted-square </v-icon>
              </v-btn>
            </div>
            <div class="box-container">
              <div class="d-flex align-center mb-5">
                <img v-svg-inline class="icon mr-3" src="@/assets/images/icons/bag_filled.svg" alt="" />
                <h2 class="mb-0">Dashboard</h2>
              </div>
              <!-- <v-row @click="goToNotificacoes()" v-if="pagamentosClienteTotal>0" link>
                <v-col cols="12">
                  <v-alert type="warning" dense>
                    Tem <span class="text-uppercase font-weight-bold"> {{ pagamentosClienteTotal }} Facturas em Validação </span>
                  </v-alert>
                </v-col>
              </v-row> -->
              <div class="row">
                <v-col cols="12">
                  <v-card-text>
                    <v-row v-if="loading">
                      <v-col cols="12">
                        <v-progress-linear indeterminate color="secondary"></v-progress-linear>
                      </v-col>
                    </v-row>

                    <v-row class="justfy-center">
                      <v-col cols="12" md="4">
                        <v-hover v-slot:default="{ hover }" open-delay="200">
                          <v-card :disabled="loading" :elevation="hover ? 16 : 0" class="mx-auto" max-width="280" style="border-radius: 02px" @click="goToMeuCarrinho()">
                            <div class="text-center">
                              <v-icon color="secondary" size="120px">mdi-cart</v-icon>
                            </div>

                            <div class="primary--text text-uppercase text-center font-weight-bold">
                              Carrinho({{ produtosCarrinhoTotal }})
                            </div>
                            <v-card-text>
                              <v-row align="center" class="mx-0"> </v-row>
                            </v-card-text>
                            <v-divider class="mx-4" />
                          </v-card>
                        </v-hover>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-hover v-slot:default="{ hover }" open-delay="200">
                          <v-card :disabled="loading" :elevation="hover ? 16 : 0" title=" Meus produtos Favoritos" class="mx-auto" max-width="280" style="border-radius: 02px" @click="goToMeusFavoritos()">
                            <div class="text-center">
                              <v-icon color="secondary" size="120px">mdi-heart</v-icon>
                            </div>

                            <div class="primary--text text-uppercase text-center font-weight-bold">
                              Meus Favoritos({{ produtosFavoritosTotal }})
                            </div>
                            <v-card-text>
                              <v-row align="center" class="mx-0"> </v-row>
                            </v-card-text>
                            <v-divider class="mx-4" />
                          </v-card>
                        </v-hover>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-hover v-slot:default="{ hover }" open-delay="200">
                          <v-card :disabled="loading" :elevation="hover ? 16 : 0" title=" Meus Pedidos" class="mx-auto" max-width="280" style="border-radius: 02px" @click="goToMeusPedidos()">
                            <div class="text-center">
                              <v-icon color="secondary" size="120px">mdi-clipboard-text</v-icon>
                            </div>

                            <div class="primary--text text-uppercase text-center font-weight-bold">
                              Meus Pedidos({{ pagamentosClienteTotal }})
                            </div>
                            <v-card-text>
                              <v-row align="center" class="mx-0"> </v-row>
                            </v-card-text>
                            <v-divider class="mx-4" />
                          </v-card>
                        </v-hover>
                      </v-col>

                      <!-- <v-col cols="12" md="4">
                        <v-hover v-slot:default="{ hover }" open-delay="200">
                          <v-card
                            disabled
                            @click="goToMeusCartoes()"
                            title=" Meu Cartão"
                            :elevation="hover ? 16 : 0"
                            class="mx-auto"
                            max-width="280"
                            style="border-radius: 02px"
                          >
                            <div class="text-center">
                              <v-icon color="secondary" size="120px">
                                mdi-credit-card</v-icon
                              >
                            </div>
                            <div
                              class="primary--text text-uppercase text-center font-weight-bold"
                            >
                              Meu Cartão
                            </div>
                            <v-card-text>
                              <v-row align="center" class="mx-0"> </v-row>
                            </v-card-text>
                            <v-divider class="mx-4" />
                          </v-card>
                        </v-hover>
                      </v-col> -->

                      <!-- <v-col cols="12" md="4">
                        <v-hover v-slot:default="{ hover }" open-delay="200">
                          <v-card disabled :elevation="hover ? 16 : 0" class="mx-auto" max-width="280" style="border-radius: 02px">
                            <div class="text-center">
                              <v-icon color="secondary" size="120px">
                                mdi-home-map-marker</v-icon>
                            </div>

                            <div class="primary--text text-uppercase text-center font-weight-bold">
                              Endereço de Entrega
                            </div>
                            <v-card-text>
                              <v-row align="center" class="mx-0"> </v-row>
                            </v-card-text>
                            <v-divider class="mx-4" />
                          </v-card>
                        </v-hover>
                      </v-col> -->

                      <v-col cols="12" md="4">
                        <v-hover v-slot:default="{ hover }" open-delay="200">
                          <v-card :disabled="loading" @click="showDialogTerminarSessao()" :elevation="hover ? 16 : 0" class="mx-auto" max-width="280" style="border-radius: 02px">
                            <div class="text-center">
                              <v-icon color="secondary" size="120px">
                                mdi-power
                              </v-icon>
                            </div>

                            <div class="primary--text text-uppercase text-center font-weight-bold">
                              Terminar sessão
                            </div>
                            <v-card-text>
                              <v-row align="center" class="mx-0"> </v-row>
                            </v-card-text>
                            <v-divider class="mx-4" />
                          </v-card>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-col>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog name="dialog-terminar-sessao" v-model="dialogTerminarSessao" max-width="300">
      <v-card>
        <!-- <v-card-title class="text-h6">Logout</v-card-title> -->
        <v-card-text class="text-center">
          <v-icon color="success" class="mt-2" size="60">mdi-information-outline</v-icon>
          <h5 class="mt-2">Pretende realmente terminar sessão?</h5>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="red darken-1" text @click="dialogTerminarSessao = false">
            Fechar
          </v-btn>
          <v-btn color="secondary darken-1" text @click="logoutCliente()">
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog name="dialog-logout-sucesso" v-model="dialogLogoutSucesso" max-width="300" persistent>
      <v-card>
        <!-- <v-card-title class="text-h6">Logout</v-card-title> -->
        <v-card-text class="text-center">
          <v-icon color="success" class="mt-2" size="50">mdi-check-circle</v-icon>
          <h6 class="mt-2">Sessão terminada com sucesso!</h6>
        </v-card-text>
        <!-- <v-card-actions>
                <v-col cols="12">
                    <v-btn color="success" @click="dialogLogoutSucesso">OK</v-btn>
                </v-col>
            </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DashboardSidebarTwo from "@/components/DashboardSidebarTwo.vue";
import axios from "axios";
import { LOGIN_URL, BASE_URL } from "@/configs/api";

export default {
  components: {
    DashboardSidebarTwo,
  },
  data() {
    return {
      dialogLogoutSucesso: false,
      pagamentosClienteTotal: 0,
      produtosFavoritosTotal: 0,
      loading: false,
      totalPedidos: 0,
      produtosCarrinhoTotal: 0,
      dialogTerminarSessao: false,
      isSidebar: false,
    };
  },
  mounted() {
    // this.getClientePagamentos()
  },
  created() {
    if (localStorage.getItem("TOKEN") != null) {
      this.getAllProdutosFavoritos();
      this.loadClienteCarrinhoProdutos();
    }
    this.checkUserIsAuth();
  },
  methods: {
    goToNotificacoes() {
      // alert("NOTIFICACOES")
      this.$router.push({
        name: "ClienteNotificacoes",
      });
    },
    checkUserIsAuth() {
      if (localStorage.getItem("TOKEN") == null) {
        // this.$router.push("/");
     this.$router.push({
        name: "PortalLogin",
      });
      }
    },
    loadClienteCarrinhoProdutos() {
      this.loading = true;
      var TOKEN_SESSAO = localStorage.getItem("TOKEN");
      const url = `${BASE_URL}/carrinho/get/my/produtos`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + TOKEN_SESSAO,
          },
        })
        .then((response) => {
          this.produtosCarrinhoTotal = response.data.data.produtos.length;
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    getClientePagamentos() {
      this.loading = true;
      var token = localStorage.getItem("TOKEN");
      const url = `${BASE_URL}/listarTodosPagamentosVendaOnlineUserAutenticado`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          this.pagamentosClienteTotal = response.data.total;

          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    goToMeuCarrinho() {
      this.$router.push({
        name: "CarrinhoResumo",
      });
    },
    goToMeusCartoes() {
      this.$router.push({
        name: "ClienteCartoes",
      });
    },
    goToMeusFavoritos() {
      this.$router.push({
        name: "ClienteProdutosFavoritos",
      });
    },
    goToMeusPedidos() {
      this.$router.push({
        name: "ClientePedidos",
      });
    },
    getAllProdutosFavoritos() {
      this.loading = true;
      var TOKEN_SESSAO = localStorage.getItem("TOKEN");
      const url = `${LOGIN_URL}/empresa/listarProdutosFavoritos`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + TOKEN_SESSAO,
          },
        })
        .then((response) => {
          this.produtosFavoritosTotal = response.data.produtos.length;
          this.getClientePagamentos();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    logoutCliente() {
      this.loading = true;
      this.dialogTerminarSessao = false;
      let token = localStorage.getItem("TOKEN");
      const url = `${LOGIN_URL}/user/logout`;
      axios
        .post(url)
        .then((response) => {
          this.dialogLogoutSucesso = true;
           if (
            sessionStorage.getItem("TOKEN") ||
            localStorage.getItem("TOKEN") ||
            sessionStorage.removeItem("USER_LOGADO") ||
            localStorage.removeItem("userLogado")
          ) {

            localStorage.removeItem("userLogado");
            localStorage.removeItem("TOKEN");
          }

          setTimeout(() => {
            this.$router.push({
              name: "/",
            });
            this.loading = false;
            this.dialogLogoutSucesso = false;
          }, 3000);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          if (
            localStorage.getItem("TOKEN") ||
            localStorage.getItem("TOKEN") ||
            sessionStorage.removeItem("USER_LOGADO") ||
            localStorage.removeItem("userLogado")
          ) {
            sessionStorage.removeItem("TOKEN");
            sessionStorage.removeItem("USER_LOGADO");
            localStorage.removeItem("userLogado");
            localStorage.removeItem("TOKEN");
            this.dialogLogoutSucesso = true;
          }

          setTimeout(() => {
            this.$router.push({
              name: "/",
            });
            this.loading = false;
            this.dialogLogoutSucesso = false;
          }, 3200);
          setTimeout(() => {
            window.location.reload();
          }, 3500);
        });
    },
    showDialogTerminarSessao() {
      this.dialogTerminarSessao = true;
    },
  },
};
</script>
